<template>
    <div class='relative md:w-full h-dvh' id='m-screen'>
        <AboutComponent/>
        <swiper
            :effect="'fade'"
            :modules='modules'
            :slides-per-view='1'
            @swiper='onSwiper'
            :allowTouchMove='false'
            @slideChangeTransitionEnd='onSlideChange'
            ref='swiperInstance'
            :loop='true'
            class='relative md:w-full h-dvh'
            v-if='slidesJsonData'
        >
            <swiper-slide
                v-for='(slideContent, index) in slidesJsonData'
                :key='index'
                class='flex justify-center items-center'>
                <div class='w-full bg-white'>
                    <div id='textEl'
                         :class="['px-4 pb-2 pt-6 font-monument md:font-clarendon flex flex-col items-center md:items-start md:text-left bottom-0 md:bottom-auto md:top-0 inverted-text tracking-normal md:tracking-tight invisible']">
                        {{ slideContent.text }}
                    </div>
                    <div class='m-container' id='m-container'>
                        <video
                            v-if='slideContent.video'
                            class='min-h-dvh object-cover w-full h-full md:w-auto md:h-auto md:max-w-full md:max-h-full'
                            width='1502'
                            height='908'
                            autoplay
                            muted
                            playsinline
                            type='video/quicktime'
                            ref="videoRefs"
                            :data-src="slideContent.video"
                        ></video>
                        <img v-else
                             :data-src='slideContent.img'
                             width='2667'
                             height='1500'
                             alt='Image'
                             class='min-h-dvh object-cover w-full h-full md:w-auto md:h-auto md:max-w-full md:max-h-full'
                             @load='onImageLoad(index)'
                             ref="imageRefs"
                        />
                    </div>
                </div>

                <!-- Clickable overlays for navigation -->
                <div class='left-overlay' @click='goToPreviousSlide'></div>
                <div class='right-overlay' @click='goToNextSlide'></div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { nextTick, onMounted, onUnmounted, ref } from 'vue';
import { EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import AboutComponent from '@/components/AboutComponent.vue';

import 'swiper/css';
import 'swiper/css/effect-fade';

export default {
    components: {
        AboutComponent,
        Swiper,
        SwiperSlide
    },
    setup() {
        const swiper = ref(null);
        const isImageLoaded = ref([]);
        const activeSlideIndex = ref(0);
        let autoSlideInterval = null;
        let setTimeOutResize = null;
        let setTimeOutFadeOut = null;
        let setTimeOutDebounce = null;
        const slidesJsonData = ref(null);
        const imageRefs = ref([]);
        const videoRefs = ref([]);

        const resizeText = () => {
            const container = document.querySelector('#m-screen');
            const textEl = document.querySelector('.swiper-slide-active #textEl');
            if (!container || !textEl) return;

            let minFontSize = 30;
            let maxFontSize = 260;
            let fontSize = maxFontSize;

            textEl.style.fontSize = fontSize + 'px';

            while (minFontSize <= maxFontSize) {
                fontSize = Math.floor((minFontSize + maxFontSize) / 2);
                textEl.style.fontSize = fontSize + 'px';

                // 40 Pixel is the padding of the container
                if (textEl.offsetWidth > container.offsetWidth || textEl.offsetHeight > container.offsetHeight - 40) {
                    maxFontSize = fontSize - 4;
                } else {
                    minFontSize = fontSize + 4;
                }
            }

            textEl.style.fontSize = maxFontSize + 'px';
            textEl.classList.remove('invisible');
            textEl.style.lineHeight = maxFontSize < 60 ? '1.05' :  '1.03';
        };

        const onSlideChange = () => {
            if (swiper.value) {
                activeSlideIndex.value = swiper.value.realIndex;
                nextTick(() => {
                    resetTimer();
                    applyTextTransition(activeSlideIndex.value);
                    if (setTimeOutResize) {
                        clearTimeout(setTimeOutResize);
                    }
                    setTimeOutResize = setTimeout(resizeText, 0);
                });
            }
        };

        const onSwiper = (swiperInstance) => {
            swiper.value = swiperInstance;
        };

        const goToPreviousSlide = () => {
            if (swiper.value) prevSlide();
        };
        const goToNextSlide = () => {
            if (swiper.value) nextSlide();
        };

        const unsubscribeToVideoEndEvent = () => {
            const activeSlide = document.querySelector('.swiper-slide-active');
            let videoElement = activeSlide.querySelector('video');
            if (videoElement) {
                videoElement.pause();
                videoElement.removeEventListener('ended',  handleVideoEnded);
            }
        };

        function setTextToInvisible() {
            const textEl = document.querySelector('.swiper-slide-active #textEl');
            if (textEl) textEl.classList.add('invisible');
        }

        const nextSlide = () => {
            unsubscribeToVideoEndEvent();
            setTextToInvisible();
            if (swiper.value) swiper.value.slideNext();
        };


        const prevSlide = () => {
            unsubscribeToVideoEndEvent();
            setTextToInvisible();
            if (swiper.value) {
                swiper.value.slidePrev();
            }
        };

        async function fetchJson(slidesJsonData) {
            try {
                const response = await fetch(`/slidesData.json?cache-bust=${Date.now()}`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache'
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const jsonData = await response.json();
                slidesJsonData.value = [...jsonData?.data];
                slidesJsonData.value.forEach(() => {
                    isImageLoaded.value.push(false);
                });
            } catch (error) {
                console.error('Error loading JSON data:', error);
            }
        }

        const onImageLoad = (index) => {
            isImageLoaded.value[index] = true;
            resizeText();
        };

        const applyTextTransition = (index) => {
            const slidesElements = document.querySelectorAll('.swiper-slide');
            slidesElements.forEach((slide) => {
                const textEl = slide.querySelector('#textEl');
                const isMatchingRealIndex = slide.getAttribute('data-swiper-slide-index') === index.toString(10);
                if (textEl) {
                    if (isMatchingRealIndex) {
                        textEl.classList.remove('fade-out');
                        textEl.classList.add('fade-in');
                        textEl.style.color = slidesJsonData.value[swiper.value.realIndex].color;
                        if (setTimeOutFadeOut) {
                            clearTimeout(setTimeOutFadeOut);
                        }
                        setTimeOutFadeOut = setTimeout(() => {
                            textEl.classList.remove('fade-in');
                            textEl.classList.add('fade-out');
                        }, 100);
                    } else {
                        textEl.classList.remove('fade-in', 'fade-out');
                    }
                }
            });
        }

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') nextSlide();
            else if (event.key === 'ArrowLeft') prevSlide();
        };

        const startAutoSlide = (customTimer = 4000) => {
            if (autoSlideInterval) clearInterval(autoSlideInterval);
            autoSlideInterval = setInterval(nextSlide, customTimer);
        };

        const stopAutoSlide = () => {
            if (autoSlideInterval) clearInterval(autoSlideInterval);
        };

        const resetTimer = () => {
            const activeSlide = document.querySelector('.swiper-slide-active');
            const videoElement = activeSlide.querySelector('video');

            stopAutoSlide();
            if (videoElement) {
                videoElement.removeEventListener('ended', handleVideoEnded);
                videoElement.addEventListener('ended', handleVideoEnded);
                videoElement.currentTime = 0;
                videoElement.play();
            } else {
                startAutoSlide();
            }
        };

        const handleVideoEnded = () => {
          nextSlide();
        };

        const applyInitialTransition = () => {
            const firstSlideTextEl = document.querySelector('.swiper-slide-active #textEl');
            if (firstSlideTextEl) {
                firstSlideTextEl.classList.add('fade-in');
                firstSlideTextEl.style.color = slidesJsonData.value[0].color;
                if (setTimeOutFadeOut) {
                    clearTimeout(setTimeOutFadeOut);
                }
                setTimeOutFadeOut = setTimeout(() => {
                    firstSlideTextEl.classList.remove('fade-in');
                    firstSlideTextEl.classList.add('fade-out');
                }, 200);
            }
        };

        const lazyLoadMedia = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const media = entry.target;
                    const src = media.getAttribute('data-src');
                    if (media.tagName === 'IMG') {
                        media.src = src;
                    } else if (media.tagName === 'VIDEO') {
                        media.src = src;
                        media.load();
                    }
                    observer.unobserve(media);
                }
            });
        };

        const observer = new IntersectionObserver(lazyLoadMedia, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });

        onMounted(async () => {
            await fetchJson(slidesJsonData);
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('load', resizeText);
            window.addEventListener('resize', debounce(resizeText, 200));
            startAutoSlide();
            nextTick(() => {
                activeSlideIndex.value = swiper.value?.realIndex || 0;
                applyInitialTransition();
                if (setTimeOutResize) {
                    clearTimeout(setTimeOutResize);
                }
                setTimeOutResize = setTimeout(resizeText, 0);
            });

            imageRefs.value.forEach(img => observer.observe(img));
            videoRefs.value.forEach(video => observer.observe(video));
        });

        onUnmounted(() => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('load', resizeText);
            window.removeEventListener('resize', resizeText);
            stopAutoSlide();
        });

        function debounce(func, wait) {
            return function(...args) {
                if(setTimeOutDebounce) {
                    clearTimeout(setTimeOutDebounce);
                }
                setTimeOutDebounce = setTimeout(() => func.apply(this, args), wait);
            };
        }

        return {
            swiper,
            slidesJsonData,
            isImageLoaded,
            onImageLoad,
            nextSlide,
            onSwiper,
            onSlideChange,
            goToPreviousSlide,
            goToNextSlide,
            activeSlideIndex,
            modules: [EffectFade],
            imageRefs,
            videoRefs
        };
    }
};
</script>

<style scoped>
.m-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.inverted-text {
    white-space: pre-wrap;
    vertical-align: top;
    position: absolute;
    z-index: 10;
}

.invisible {
    opacity: 0 !important;
}

.fade-in {
    transition: opacity 1s ease-in-out;
    opacity: 1 !important;
}

.fade-out {
    transition: color 1s linear;
    color: white !important;
    mix-blend-mode: difference;
}

/* Overlays for detecting clicks */
.left-overlay,
.right-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    cursor: pointer;
    z-index: 10;
}

.left-overlay {
    left: 0;
}

.right-overlay {
    right: 0;
}

</style>