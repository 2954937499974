<template>
    <div class="absolute top-0 w-full p-6 cursor-pointer flex flex-col items-center md:items-start" @click.stop='toggleMenu'>
        <div class="inverted-text font-monument leading-tight">Mirabella-Morganti</div>
    </div>

    <!-- Overlay -->
    <div class="fixed top-0 left-0 z-40 w-screen h-dvh bg-[#1A1919] transition-transform duration-1000 ease-in-out transform"
         @click.stop='closeMenu'
         :class="{'-translate-x-full': !isMenuOpen, 'translate-x-0': isMenuOpen}">

      <div class="p-6 h-full text-left text-[#FFFFFE] grid md:grid-cols-12 gap-2 relative">
        <div class="md:col-span-2 font-monument text-center md:text-left leading-tight ">Mirabella-Morganti</div>
        <div class="md:col-span-8 py-6 md:pt-0 font-monument leading-tight overflow-y-scroll no-scrollbar" >

          <!-- insert data from json here  -->
          <div id="description"></div>

          Mirabella–Morganti
          <br>Kochstrasse 18
          <br>CH–8004 Zurich
          <br>
          <span class="underline m-mail cursor-pointer" @click.stop='mailTo()'></span>

          <br><br>
          Follow us on <a href="https://www.instagram.com/rebeccamorgantipfaffhauser/" class="underline" target="_blank">instagram</a>
          <br><br>

          Coding by:
          <a href="https://isabelmeraner.github.io/" class="underline" target="_blank">izi</a> and
          <a href="https://riorudo.space/home" class="underline" target="_blank">riorudo</a>



  </div>
      </div>
    </div>
</template>


<script setup lang='ts'>
import {ref} from 'vue';

const closeMenu = () => {
  isMenuOpen.value = false;
};

const isMenuOpen = ref(false);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

fetch('/aboutData.json') // Replace with your actual JSON path
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(json => {
      document.querySelector('#description')!.innerHTML = json.data.description;
    })
    .catch(error => console.error('Error loading JSON:', error));

const mailTo = () => {
    const mail1 = 'hello@';
    const mail2 = 'mirabella-morganti.ch';
    window.open(`mailto:${mail1}${mail2}`, '_blank');
}


</script>

<style scoped>
.inverted-text {
  mix-blend-mode: difference;
  white-space: nowrap;
  vertical-align: top;
  color: white;
  position: absolute;
  z-index: 10;
}

/* Added styles */
.fixed {
  position: fixed;
  overflow-y: auto;
}

.m-mail:before {
    content: "\0068\0065\006C\006C\006F\0040"; /* "hello@" */
}

.m-mail:after {
    content: "\006D\0069\0072\0061\0062\0065\006C\006C\0061\002D\006D\006F\0072\0067\0061\006E\0074\0069\002E\0063\0068"; /* "mirabella-morganti.ch" */
}

</style>