import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-6 h-full text-left text-[#FFFFFE] grid md:grid-cols-12 gap-2 relative" }
const _hoisted_2 = { class: "md:col-span-8 py-6 md:pt-0 font-monument leading-tight overflow-y-scroll no-scrollbar" }

import {ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AboutComponent',
  setup(__props) {

const closeMenu = () => {
  isMenuOpen.value = false;
};

const isMenuOpen = ref(false);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

fetch('/aboutData.json') // Replace with your actual JSON path
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(json => {
      document.querySelector('#description')!.innerHTML = json.data.description;
    })
    .catch(error => console.error('Error loading JSON:', error));

const mailTo = () => {
    const mail1 = 'hello@';
    const mail2 = 'mirabella-morganti.ch';
    window.open(`mailto:${mail1}${mail2}`, '_blank');
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "absolute top-0 w-full p-6 cursor-pointer flex flex-col items-center md:items-start",
      onClick: _withModifiers(toggleMenu, ["stop"])
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "inverted-text font-monument leading-tight" }, "Mirabella-Morganti", -1)
    ])),
    _createElementVNode("div", {
      class: _normalizeClass(["fixed top-0 left-0 z-40 w-screen h-dvh bg-[#1A1919] transition-transform duration-1000 ease-in-out transform", {'-translate-x-full': !isMenuOpen.value, 'translate-x-0': isMenuOpen.value}]),
      onClick: _withModifiers(closeMenu, ["stop"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "md:col-span-2 font-monument text-center md:text-left leading-tight" }, "Mirabella-Morganti", -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { id: "description" }, null, -1)),
          _cache[3] || (_cache[3] = _createTextVNode(" Mirabella–Morganti ")),
          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
          _cache[5] || (_cache[5] = _createTextVNode("Kochstrasse 18 ")),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _cache[7] || (_cache[7] = _createTextVNode("CH–8004 Zurich ")),
          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", {
            class: "underline m-mail cursor-pointer",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (mailTo()), ["stop"]))
          }),
          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
          _cache[11] || (_cache[11] = _createTextVNode(" Follow us on ")),
          _cache[12] || (_cache[12] = _createElementVNode("a", {
            href: "https://www.instagram.com/rebeccamorgantipfaffhauser/",
            class: "underline",
            target: "_blank"
          }, "instagram", -1)),
          _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
          _cache[15] || (_cache[15] = _createTextVNode(" Coding by: ")),
          _cache[16] || (_cache[16] = _createElementVNode("a", {
            href: "https://isabelmeraner.github.io/",
            class: "underline",
            target: "_blank"
          }, "izi", -1)),
          _cache[17] || (_cache[17] = _createTextVNode(" and ")),
          _cache[18] || (_cache[18] = _createElementVNode("a", {
            href: "https://riorudo.space/home",
            class: "underline",
            target: "_blank"
          }, "riorudo", -1))
        ])
      ])
    ], 2)
  ], 64))
}
}

})